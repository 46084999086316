import React from 'react';
import '@/assets/scss/components/project-review/review-summary.scss';
import StarSVG from '@/assets/images/generic-icons/star.svg';
import ProgressBar from '@/assets/scss/pages/progress-bar/index.js';
import StarRatings from 'react-star-ratings';
export default class summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressBarArray: [
        {
          ratings: 5,
          percentage: 85
        },
        {
          ratings: 4,
          percentage: 75
        },
        {
          ratings: 3,
          percentage: 20
        },
        {
          ratings: 2,
          percentage: 0
        },
        {
          ratings: 1,
          percentage: 3
        }
      ],
      rating: 3.5
    };
  }
  render() {
    const { progressBarArray } = this.state;
    return (
      <div className="review-summary">
        <div className="review-title d-flex align-items-center justify-content-between">
          <h3 className="normal-font">Review Summary</h3>
          <a href="#0">+ Write a Review</a>
        </div>
        <h6 className="normal-font mt-3 theme-secondary-color">
          UKN Miraya Rose in Whitefield
        </h6>
        <p className="light-font small-font">
          Developed / Built by UKN Properties pvt ltd
        </p>
        <div className="d-flex align-items-center justify-content-between flex-direction-column-mobile">
          <div className="review-summary--ratings-wrapper">
            <div className="ratings mt-4">
              <ul>
                {progressBarArray.map((progressObj, index) => {
                  return (
                    <li className="d-flex align-items-center" key={index}>
                      <div className="ratings-number mr-3 d-flex">
                        {progressObj.ratings}{' '}
                        <img src={StarSVG} className="img-fluid" alt="" />
                      </div>
                      <ProgressBar progress={progressObj.percentage} />
                      <h3 className="normal-font ml-4">
                        {progressObj.percentage}%
                      </h3>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="review-summary--points-wrapper d-flex align-items-center">
            <h2 className="mr-1">4.5</h2>
            <div className="review-info">
              <h6 className="normal-font">Excellent</h6>
              <StarRatings
                rating={this.state.rating}
                starRatedColor="#f9be2b"
                starHoverColor="#f9be2b"
                starDimension="18px"
                starSpacing="1px"
                numberOfStars={5}
                name="rating"
              />
              <p className="light-font mt-1">137 Reviews</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
