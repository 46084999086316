import React from 'react';
import '@/assets/scss/pages/project-details/project-description.scss';
import AminitySVG from '@/assets/images/generic-icons/aminites.svg';
import CorrectSVG from '@/assets/images/generic-icons/correct.svg'
export default class description extends React.Component {
  render() {
    return (
      <div className="project--description-wrapper">
        <h4 className="medium-bold-font">Project Description</h4>
        <p className="light-font">
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000 years old. Richard McClintock, a Latin professor at
          Hampden-Sydney College in Virginia, looked up one of the more obscure
          Latin words, consectetur, from a Lorem Ipsum passage, and going
          through the cites of the word in classical literature, discovered the
          undoubtable source. Lorem Ipsum comes from sections 1.10.32 and
          1.10.33 of “de Finibus Bonorum et Malorum” (The Extremes of Good and
          Evil) by Cicero, written in 45 BC. This book is a treatise on the
          theory of ethics, very popular
        </p>
        <div className="project--description-wrapper--amenities d-flex">
            <div className="aminites-icon mt-1">
                <img src={AminitySVG}/>
            </div>
            <div className="aminites-details">
                <h4 className="normal-font">Amenities</h4>
                <div className="aminity-list">
                <ul>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Reserved Parking</li>
                    <li><img src={CorrectSVG}/>Waste Disposal</li>
                    <li><img src={CorrectSVG}/>Swimming Poole</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                    <li><img src={CorrectSVG}/>Laundry Service</li>
                </ul>
                </div>
            </div>
        </div>
      </div>
    );
  }
}
