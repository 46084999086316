import React from 'react';
import '@/assets/scss/pages/project-community/question-and-answers.scss';
import Dropdown from '@/assets/svg/dropdown.js';

export default class ProjectCommunityQuestionAnswers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionsArray: [{
        question: 'What is the monthly maintenance for 2 BHK?',
        answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
      {
        question: 'Is there any restrictions to conduct dance classes at home?',
        answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
      {
        question: 'What is the maximum number of car parking space one can buy?',
        answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
      {
        question: 'What is the monthly maintenance for 2 BHK?',
        answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
      {
        question: 'Is there any restrictions to conduct dance classes at home?',
        answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
      {
        question: 'What is the maximum number of car parking space one can buy?',
        answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      }],
    }
  }
  render() {
    const { questionsArray } = this.state;
    return (
      <div className="project-qanda-wrapper">
        {
          questionsArray.map((questionObj, index) => {
            return (
              <div className="project-qanda-wrapper--accordian" key={index}>
                <input type="checkbox" name="project-qanda-accordian" id={`question${index+1}`} className="hidden-input"></input>
                <label htmlFor={`question${index+1}`} className="d-flex align-items-center justify-content-between project-qanda-wrapper--accordian--header">
                  <div className="question">
                    <img src="/images/generic-icons/question.svg" className="img-fluid mr-3" alt="Question Icon"/>
                    {questionObj.question}
                  </div>
                  <Dropdown />
                </label>
                <div className="project-qanda-wrapper--accordian--body">
                  <p>{questionObj.answer}</p>
                  <p className="small-font mt-3">Response posted on 22, Jan 2021</p>
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }
}
