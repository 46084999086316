import React from 'react';
import '@assets/scss/pages/project-details/project-details.scss';
import Location from '@/components/project-location/location-summary.js';
import Description from '@/components/project-location/location-description.js';
import Callback from '@/components/project-overview/request-callback.js';
import Slider from '@/components/project-overview/project-overview-slider.js';
import Reviewcard from '@/components/project-details/review-card.js';
import Askcommunity from '@/components/project-details/ask-community.js';
import PropertyNavigation from '@/components/project-overview/project-navigation-menu.js';

export default class details extends React.Component {
  render() {
    return (
      <section className="property-details-wrapper">
        <PropertyNavigation />
        <div className="property-details-wrapper--image-slider">
          <Slider />
        </div>
        <div className="property-details-wrapper--overview">
          <div className="d-flex justify-content-between">
            <div className="property-overview">
              <Location />
              <Description />
            </div>
            <div className="details-callback-container d-none-mobile d-none-medium">
             <div className="details-callback">
              <Callback/>
            </div>  
            <div className="details-callback">
              <Reviewcard/>
            </div> 
            <div className="details-callback">
              <Askcommunity/>
            </div> 
            </div>
          </div>
        </div>
      </section>
    );
  }
}
