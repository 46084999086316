import React from 'react';
import '@assets/scss/components/home/property-type-card.scss';
// import Homeimage from '@assets/images/home-img.png';

export default class PropertyTypeCard extends React.Component {
  render() {
    return (
      <div className="prperty-type-card-wrapper text-center">
        <img src={this.props.propertyTypeArrayPropObj.imgURL} className="mb-4"/>
        <h3 className="text-center normal-font">{this.props.propertyTypeArrayPropObj.heading}</h3>
      </div>
    );
  }
}
