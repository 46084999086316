import React from 'react';
import '@assets/scss/pages/reward-points/reward-point-card.scss';

export default class Rewardscard extends React.Component {
    constructor(props) {
        super(props);
    }
    render(){
        return(
            <div className="reward-point-card">
                <img src={this.props.rewardPropsObj.imageURL} className="img-fluid"/>
                <div className="reward-point-card--details">
                    <h3 className="normal-font">{this.props.rewardPropsObj.title}</h3>
                    <p className="light-font">{this.props.rewardPropsObj.description}</p>
                    <a href="#0" onClick={this.props.showClaimRewardsPopup}>{this.props.rewardPropsObj.link}</a>
                </div>
            </div>
        )
    }
}