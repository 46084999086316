import React from 'react';
import '@/assets/scss/components/progress-bar/progress-bar.scss';

export default class ProgressBar extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="progress-bar-wrapper">
                <div className="progress-bar-wrapper--progress" style={{width: `${this.props.progress}%`}}></div>
            </div>
        )
    }
}