import React from 'react';
import '@assets/scss/pages/project-overview/project-overview.scss';
import Swiper from 'swiper/bundle';
import 'swiper/swiper.scss';
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import House1 from '@/assets/images/property-overview/houseimage1.png';
import HouseImageLarge1 from '@/assets/images/property-overview/house1large.png';
import HouseImageLarge2 from '@/assets/images/property-overview/house2large.png';
import House2 from '@/assets/images/property-overview/houseimage2.png';
import House3 from '@/assets/images/property-overview/houseimage3.png';
import House4 from '@/assets/images/property-overview/houseimage4.png';

export default class slider extends React.Component {
  initializePropertySlider() {
    new Swiper('.property-overview-banner-iamges', {
      loop: false,
      spaceBetween: 2,
      slidesPerView: 'auto',
      autoplay: {
        delay: 5000
      },
      navigation: {
        nextEl: '.property-type-slide-right',
        prevEl: '.property-type-slide-left'
      }
    });
  }
  componentDidMount() {
    this.initializePropertySlider();
  }
  render() {
    return (
      <LightgalleryProvider>
        <div className="swiper-container property-overview-banner-iamges">
          <div className="swiper-wrapper" id="lighthouse">
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge1}><img src={House1} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge2}><img src={House2} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge1}><img src={House3} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge2}><img src={House4} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge1}><img src={House1} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge2}><img src={House2} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge1}><img src={House3} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge2}><img src={House4} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge1}><img src={House1} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge2}><img src={House2} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge1}><img src={House3} className="w-100 "/></LightgalleryItem>
            </div>
            <div className="swiper-slide slide-max-width cursor-pointer">
              <LightgalleryItem group="any" src={HouseImageLarge2}><img src={House4} className="w-100 "/></LightgalleryItem>
            </div>
          </div>
        </div>
      </LightgalleryProvider>
    )
  }
}
     
     
          
  
  

