import React from 'react';
import '@assets/scss/pages/project-details/project-details-summary.scss';
import Personicon from '@/assets/images/generic-icons/personicon.svg'
import Bedicon from '@/assets/images/generic-icons/bed.svg';
import Priceicon from '@/assets/images/generic-icons/price.svg';
import Houseicon from '@/assets/images/generic-icons/home.svg';
import Appartmenticon from '@/assets/images/generic-icons/appartment.svg';
import Landicon from '@/assets/images/generic-icons/land.svg';
import Buildingicon from '@/assets/images/generic-icons/building.svg';
export default class summary extends React.Component {
  render() {
    return (
      <div className="project-summary-details">
        <h4 className="normal-font">Project Summary</h4>
        <h2 className="normal-font">
          UKN Miraya Rose in Whitefield
        </h2>
        <div className="icon d-flex align-items-center">
        <img src={Personicon}/>
        <p className="light-font">Developed / Built by UKN Properties pvt ltd</p>
        </div>
        <div className="price-cards">
          <div className="project-details d-flex justify-content-between align-items-start">
            <div className="icon">
              <img src={Priceicon} />
              <p className="light-font">Price range</p>
            </div>
            <div className="price">
              <h4 className="normal-font">INR 2.4 Cr - 6.43 Cr</h4>
            </div>
          </div>
          <div className="project-details d-flex justify-content-between">
            <div className="icon">
              <img src={Bedicon} />
              <p className="light-font">Configuratione</p>
            </div>
            <div className="price">
              <h4 className="normal-font">3, 4 BHK Flat</h4>
            </div>
          </div>
          <div className="project-details d-flex justify-content-between">
            <div className="icon">
              <img src={Landicon} />
              <p className="light-font">Area</p>
            </div>
            <div className="price">
              <h4 className="normal-font">1253 - 1950 sqft</h4>
            </div>
          </div>
          <div className="project-details d-flex justify-content-between">
            <div className="icon">
              <img src={Appartmenticon} />
              <p className="light-font">Property type</p>
            </div>
            <div className="price">
              <h4 className="normal-font">Apartment</h4>
            </div>
          </div>
          <div className="project-details d-flex justify-content-between">
            <div className="icon">
              <img src={Buildingicon} />
              <p className="light-font">Possession</p>
            </div>
            <div className="price">
              <h4 className="normal-font">Dec, 2021 (Ongoing)</h4>
            </div>
          </div>
          <div className="project-details d-flex justify-content-between">
            <div className="icon">
              <img src={Houseicon} />
              <p className="light-font">Tower & Units</p>
            </div>
            <div className="price">
              <h4 className="normal-font">1 Tower & 87 units</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
