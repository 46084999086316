import React from 'react';
import '@/assets/scss/pages/project-details/project-description.scss';
import LocateSVG from '@/assets/images/property-overview/locate.svg';
import CorrectSVG from '@/assets/images/generic-icons/correct.svg';
export default class description extends React.Component {
  render() {
    return (
      <div className="project--description-wrapper">
        <h4 className="medium-bold-font">Location Description</h4>
        <p className="light-font">
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000 years old. Richard McClintock, a Latin professor at
          Hampden-Sydney College in Virginia, looked up one of the more obscure
          Latin words, consectetur, from a Lorem Ipsum passage, and going
          through the cites of the word in classical literature, discovered the
          undoubtable source. Lorem Ipsum comes from sections 1.10.32 and
          1.10.33 of “de Finibus Bonorum et Malorum” (The Extremes of Good and
          Evil) by Cicero, written in 45 BC. This book is a treatise on the
          theory of ethics, very popular
        </p>
        <div className="project--description-wrapper--amenities d-flex">
          <div className="aminites-icon mt-1">
            <img src={LocateSVG} />
          </div>
          <div className="aminites-details">
            <h5 className="normal-font mb-3">Nearby Places</h5>
            <div className="aminity-list">
              <ul>
                <li className="w-100">
                  <img src={CorrectSVG} />
                  Lalbagh Botinal Garden (4.3 kms away)
                </li>
                <li className="w-100">
                  <img src={CorrectSVG} />
                  Cubbon Park (3.2 kms away)
                </li>
                <li className="w-100">
                  <img src={CorrectSVG} />
                  Vidhana soudha (4.1 kms away)
                </li>
                <li className="w-100">
                  <img src={CorrectSVG} />
                  Fortis hospital (3.0 kms away)
                </li>
                <li className="w-100">
                  <img src={CorrectSVG} />
                  Laundry Service
                </li>
                <li className="w-100">
                  <img src={CorrectSVG} />
                  Laundry Service
                </li>
                <li className="w-100">
                  <img src={CorrectSVG} />
                  Swimming Poole
                </li>
                <li className="w-100">
                  <img src={CorrectSVG} />
                  Fortis hospital (3.0 kms away)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
