import React from 'react';
import Rewardimage from '@/assets/images/property-overview/reward-image.png';
import '@/assets/scss/pages/project-details/reward.scss';
import ReviewPropertyPopup from '@/components/project-community-answer/review-ratings-popup.js';

export default class reward extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewPropertyPopupFlag: false
    }
  }
  toogleRatePropertyPopup = () => {
    this.setState({
      reviewPropertyPopupFlag: !this.state.reviewPropertyPopupFlag
    })
  }
  render() {
    return (
      <section className="reward-wrapper">
        <div className="text-center">
          <img src={Rewardimage} />
        </div>
        <div className="reward-wrapper--details">
          <h4 className="normal-font">
            Write a property review and earn rewards
          </h4>
          <p className="light-font">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry’s standard dummy text
            ever since the 1500s,
          </p>
          <a onClick={this.toogleRatePropertyPopup} className="cursor-pointer">+ Write a Review</a>
          <ReviewPropertyPopup reviewPropertyPopupFlag={this.state.reviewPropertyPopupFlag} closePopup={this.toogleRatePropertyPopup} />
        </div>
      </section>
    );
  }
}
