import React from 'react';
import PopupImage from '@/assets/images/reward-points/reward-popup-image.svg';
import '@assets/scss/pages/reward-points/reward-points-popup.scss';
import CloseSVG from '@/assets/svg/closeSVG.js';

export default class Rewardpointspopup extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        <div className={
            this.props.rewardsPopupFlag
              ? 'rewards-wrapper-popup active'
              : 'rewards-wrapper-popup'
          }>
          <div className="rewards-wrapper-popup--popup position-relative text-center">
            <a className="close-popup cursor-pointer position-absolute"  onClick={this.props.closeClaimRewardsPopup}>
              <CloseSVG />
            </a>
            <h3 className="normal-font">Rebyuu Points Redeemed</h3>
            <img src={PopupImage} className="img-fluid" />
            <p className="light-font">
              Your Request to redeem points has been received. You will receive
              the confirmation and relevant details soon in your registered
              email address.
            </p>
          </div>
        </div>
    );
  }
}
