import React from 'react';
import FaviconSVG from '@/assets/images/property-overview/fav-icon.svg';
import '@assets/scss/pages/project-overview/project-summary.scss';
export default class summary extends React.Component {
  render() {
    return (
      <div className="project-summary">
        <h2 className="normal-font">Project Summary</h2>
        <h4 className="normal-font mt-2 red-color">
          UKN Miraya Rose in Whitefield
        </h4>
        <p className="light-font">Developed / Built by UKN Properties pvt ltd</p>
        <div className="price-cards">
          <div className="project-details d-flex justify-content-between align-items-start">
            <div className="icon">
              <img src={FaviconSVG} />
              <p className="light-font">Price range</p>
            </div>
            <div className="price">
              <h4 className="normal-font">INR 2.4 Cr - 6.43 Cr</h4>
            </div>
          </div>
          <div className="project-details d-flex justify-content-between">
            <div className="icon">
              <img src={FaviconSVG} />
              <p className="light-font">Configuratione</p>
            </div>
            <div className="price">
              <h4 className="normal-font">3, 4 BHK Flat</h4>
            </div>
          </div>
          <div className="project-details d-flex justify-content-between">
            <div className="icon">
              <img src={FaviconSVG} />
              <p className="light-font">Area</p>
            </div>
            <div className="price">
              <h4 className="normal-font">1253 - 1950 sqft</h4>
            </div>
          </div>
          <div className="project-details d-flex justify-content-between">
            <div className="icon">
              <img src={FaviconSVG} />
              <p className="light-font">Possession</p>
            </div>
            <div className="price">
              <h4 className="normal-font">Dec, 2021 (Ongoing)</h4>
            </div>
          </div>
        </div>
        <div className="link">
          <a href="/project-details">View more project details</a>
        </div>
      </div>
    );
  }
}
