import React from 'react';
import '@assets/scss/header/site-header.scss';
import NavigationMenu from '@/components/header/navigation-menu';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          showHamburgerMenu: false
        }
    }
    toogleMenu = () => {
        this.setState({
            showHamburgerMenu: !this.state.showHamburgerMenu
        })
    }
	render() {
		return (
            <header className="header-wrapper">
                <div className="custom-container">
                    <div className="header-wrapper--logo-menu d-flex align-items-center justify-content-between">
                        <div className="icon-logo d-flex align-items-center">
                            <i className="material-icons cursor-pointer" onClick={this.toogleMenu}>menu</i>
                            <h3 className="ml-4"><a href="/">Logo</a></h3>
                        </div>
                        <div className="header-buttons">
                            <a className="theme-button theme-secondary-btn margin-right display-inline-block" href="/login-register">Sign in</a>
                            <button className="theme-button theme-primary-btn ml-3 d-none-mobile">Write a review</button>
                        </div>
                    </div>
                </div>
                <NavigationMenu showMenu={this.state.showHamburgerMenu} />
                <div className={this.state.showHamburgerMenu ? 'navigation-menu-backdrop active': 'navigation-menu-backdrop'} onClick={this.toogleMenu}></div>
            </header>
        );
	}
}
