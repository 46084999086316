import React from 'react';
import '@assets/scss/components/home/banner.scss';
import Bannerimage from '@assets/svg/banner.svg';
import Propertysearchform from '@components/home-page/banner/property-search.js';

export default class Banner extends React.Component {
  render() {
    return (
      <section className="banner-wrapper">
        <div className="custom-container">
          <h1>
            Buying properties? <span className="d-none-mobile">Want property ratings and reviews?</span> You’re In the
            right place.
          </h1>
          <Propertysearchform />
        </div>
        <img src={Bannerimage} className="img-fluid w-100" alt="" />
      </section>
    );
  }
}
