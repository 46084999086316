import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import '@/assets/scss/pages/project-community/ask-question-popup.scss';
import CloseSVG from '@/assets/svg/closeSVG.js';

export default class AskAQuestionPopup extends React.Component {
    constructor(props) {
      super(props);
    }
    render () {
        return (
            <div className={this.props.askAQuestionPopupFlag ? 'ask-question-popup-wrapper active' : 'ask-question-popup-wrapper'}>
                <div className="ask-question-popup-wrapper--popup position-relative">
                    <a className="close-popup position-absolute cursor-pointer" onClick={this.props.closePopup}>
                        <CloseSVG />
                    </a>
                    <h2>Ask a Question</h2>
                    <p>About UKN Miraya Rose in Whitefield</p>
                    <FormGroup className="mt-3 mb-3 ask-question-form-element">
                        <Input type="textarea" name="text" id="exampleText" rows="7" />
                    </FormGroup>
                    <div className="ask-question-popup-wrapper--cta-wrapper text-right">
                        <button className="theme-button theme-secondary-btn" onClick={this.props.closePopup}>Cancel</button>
                        <button className="theme-button theme-primary-btn">Save</button>
                    </div>
                </div>
            </div>
        )
    }
}