import React from 'react';
import Askcommunityimg from '@/assets/images/property-overview/askcommunity.png';
import '@/assets/scss/pages/project-details/reward.scss';
import AskAQuestionPopup from '@/components/project-community-answer/ask-a-question-popup.js';

export default class reward extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      askAQuestionPopupFlag: false,
    }
  }
  toogleAskQuestionPopup = () => {
    this.setState({
      askAQuestionPopupFlag: !this.state.askAQuestionPopupFlag
    })
  }
  render() {
    return (
      <section className="reward-wrapper">
        <div className="text-center">
          <img src={Askcommunityimg} />
        </div>
        <div className="reward-wrapper--details">
          <h4 className="normal-font">
          Ask the community
          </h4>
          <p className="light-font">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry’s standard dummy text
            ever since the 1500s,
          </p>
          <a className="cursor-pointer" onClick={this.toogleAskQuestionPopup}>+ Ask a question</a>
          <AskAQuestionPopup askAQuestionPopupFlag={this.state.askAQuestionPopupFlag} closePopup={this.toogleAskQuestionPopup} />
        </div>
      </section>
    );
  }
}
