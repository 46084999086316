import React from 'react';
import '@assets/scss/components/home/customer-stories/customer-story-card.scss';
// import customerimage from '@/assets/images/customerstory.png';

export default class CustomerCard extends React.Component {
  render() {
    return (
      <div className="customer-card-wrapper">
        <img src={this.props.CustomerstoriesPropObj.imgURL} className="img-fluid"/>
        <div className="customer-details">
            <p className="normal-font mb-2">{this.props.CustomerstoriesPropObj.stories}</p>
            <h3 className="medium-bold-font mb-4">{this.props.CustomerstoriesPropObj.heading}</h3>
            <p className="normal-font">{this.props.CustomerstoriesPropObj.date}</p>
        </div>
      </div>
    );
  }
}
