import React from 'react';
import '@assets/scss/components/home/popular-properties-slider.scss';
import Propertiescard from '@components/property-card/index.js';
import Swiper from 'swiper/bundle';
import 'swiper/swiper.scss';

export default class PopularpropertiesSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popularPropertiesArray: [
        {
          reviewsCount: 43,
          price: '93 Lakhs',
          rating: 4,
          apartmentType: '3BHK Apartment',
          location: 'JP Nagar phase 9',
          superArea: '4500 Sqft',
          society: 'Prestige Spencer Heights',
          imageURL:
            '/images/properties/property-2.png'
        },
        {
          reviewsCount: 23,
          price: '50 Lakhs',
          rating: 3.5,
          apartmentType: '2BHK Apartment',
          location: 'JP Nagar phase 6',
          superArea: '2500 Sqft',
          society: 'Prestige Spencer Heights New',
          imageURL: '/images/properties/property-1.png'
        },
        {
          reviewsCount: 34,
          price: '30 Lakhs',
          rating: 2.8,
          apartmentType: '1BHK Apartment',
          location: 'JP Nagar phase 1',
          superArea: '1500 Sqft',
          society: 'Prestige Spencer',
          imageURL: '/images/properties/property-1.png'
        },
        {
          reviewsCount: 43,
          price: '93 Lakhs',
          rating: 4,
          apartmentType: '3BHK Apartment',
          location: 'JP Nagar phase 9',
          superArea: '4500 Sqft',
          society: 'Prestige Spencer Heights',
          imageURL: '/images/properties/property-2.png'
        },
        {
          reviewsCount: 23,
          price: '50 Lakhs',
          rating: 3.5,
          apartmentType: '2BHK Apartment',
          location: 'JP Nagar phase 6',
          superArea: '2500 Sqft',
          society: 'Prestige Spencer Heights New',
          imageURL: '/images/properties/property-1.png'
        },
        {
          reviewsCount: 34,
          price: '30 Lakhs',
          rating: 2.8,
          apartmentType: '1BHK Apartment',
          location: 'JP Nagar phase 1',
          superArea: '1500 Sqft',
          society: 'Prestige Spencer',
          imageURL: '/images/properties/property-1.png'
        }
      ]
    };
  }
  initializePropertySlider() {
    new Swiper('.popular-property-slider', {
      loop: false,
      slidesPerView: 'auto',
      autoplay: {
        delay: 5000
      },
      spaceBetween: 16,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });
  }
  componentDidMount() {
    this.initializePropertySlider();
  }
  render() {
    const popularPropertiesArray = this.state.popularPropertiesArray;
    return (
      <div className="popular-properties-wrapper--slider">
        <div className="swiper-container popular-property-slider">
          <div className="swiper-wrapper">
            {popularPropertiesArray.map((propertyObj, index) => {
              return (
                <div key={index} className="swiper-slide property-card-width">
                  <Propertiescard propertyPropObj={propertyObj} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
