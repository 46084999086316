import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routes from '@/routes/routes';
import Pagenotfound from '@pages/page-not-found.js';
import Siteheader from '@components/header/index';
import GetStarted from '@/components/get-started/index';
import Sitefooter from '@components/footer/index';

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function RebyuuRouting() {
	return (
		<Router>
			<Siteheader />
			<Switch>
				{routes.map((routeObj, index) => {
					return (
						<Route
							key={index}
							path={routeObj.path}
							exact
							component={routeObj.component}
						/>
					);
				})}
				<Route path="*" component={Pagenotfound} />
			</Switch>
			<GetStarted/>
			<Sitefooter/>
		</Router>
	);
}
