import React from 'react';
import '@assets/scss/components/home/customer-stories/customer-stories.scss';
import Arrowback from '@assets/svg/arrowback.svg';
import Arrowforward from '@assets/svg/arrowforward.svg';
import CustomerStorySlider from '@/components/home-page/customer-stories/customer-story-slider.js';
//import CustomerCard from '@/components/home-page/customer-stories/customer-story-card.js';

export default class CustomerStory extends React.Component {
  render() {
    return (
      <div className="customer-story-wrapper">
        <div className="custom-container">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="customer-story-wrapper--title">
              <h2 className="medium-bold-font">
                Customer Stories
              </h2>
            </div>
            <div className="customer-story-wrapper--cta-wrapper">
              <ul>
                <li>
                  <div className="arrow mr-2 mr-md-4 CustomerCard-slide-left">
                    <img src={Arrowback} />
                  </div>
                </li>
                <li>
                  <div className="arrow CustomerCard-slide-right">
                    <img src={Arrowforward} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <CustomerStorySlider/>
        </div>
      </div>
    );
  }
}
