import React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import '@/assets/scss/pages/login/login-form.scss';

export default class LoginFormBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentScreen: 1,
        }
    }
    submitPhoneNumber = (e) => {
        if (Number(e.target.getAttribute('data-next-screen')) === 4) {
            window.location.href = '/profile';
        } else {
            this.setState({
                currentScreen: Number(e.target.getAttribute('data-next-screen'))
            })
        }
        e.preventDefault();
    }
    render() {
        return (
            <section className="login-form-wrapper">
                <div className="custom-container d-flex justify-content-between flex-column-reverse flex-md-row">
                    <div className="login-form-wrapper--form">
                        <div className={(this.state.currentScreen === 1) ? 'login-screen-1' : 'login-screen-1 d-none'}>
                            <h4 className="mb-2">LOGO</h4>
                            <h2 className="mb-4">Login/Signup</h2>
                            <Form data-next-screen="2" onSubmit={this.submitPhoneNumber}>
                                <FormGroup>
                                    <Label for="fulmobilelname">Email or Mobile Number</Label>
                                    <Input type="text" name="mobile" id="fullname" placeholder="Enter Email ID or Mobile Number" />
                                </FormGroup>
                                <FormGroup className="mb-0">
                                    <button className="theme-button theme-primary-btn bold-font w-100">Continue</button>
                                </FormGroup>
                            </Form>
                            <div className="login-form-wrapper--form--divider text-center">
                                <span>or</span>
                            </div>
                            <div className="login-form-wrapper--form--social-login">
                                <ul className="d-flex">
                                    <li>
                                        <a href="#0">
                                            <img src="/images/login/social-login/facebook.svg" className="img-fluid" alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#0">
                                            <img src="/images/login/social-login/google.svg" className="img-fluid" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={(this.state.currentScreen === 2) ? 'login-screen-2' : 'login-screen-2 d-none'}>
                            <h2 className="mb-4">Verify your Mobile Number</h2>
                            <Form data-next-screen="3" onSubmit={this.submitPhoneNumber}>
                                <FormGroup>
                                    <Label for="otp">OTP (its been sent to your mobile number)</Label>
                                    <div className="position-relative resend-otp-wrapper">
                                        <Input type="text" name="mobile" id="otp" maxLength="4" placeholder="Enter OTP" />
                                        <a className="cursor-pointer position-absolute theme-primary-color medium-bold-font">Resend OTP</a>
                                    </div>
                                </FormGroup>
                                <FormGroup className="mb-0">
                                    <button className="theme-button theme-primary-btn bold-font w-100">Continue</button>
                                </FormGroup>
                            </Form>
                        </div>
                        <div className={(this.state.currentScreen === 3) ? 'login-screen-3' : 'login-screen-2 d-none'}>
                            <h2 className="mb-4">Almost There!</h2>
                            <Form data-next-screen="4" onSubmit={this.submitPhoneNumber}>
                                <FormGroup>
                                    <Label for="title">Title</Label>
                                    <Input type="text" name="title" id="title" placeholder="Select Title" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Name">Name *</Label>
                                    <Input type="text" name="Name" id="Name" placeholder="Enter Name" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="emailaddress">Email address *</Label>
                                    <Input type="text" name="emailaddress" id="emailaddress" placeholder="Enter Email Address" />
                                </FormGroup>
                                <FormGroup className="mb-0">
                                    <button className="theme-button theme-primary-btn bold-font w-100">Continue</button>
                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                    <div className="login-form-wrapper--banner-image">
                        <img src="/images/login/login-banner.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </section>
        )
    }
}