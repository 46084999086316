import React from 'react';
import '@assets/scss/pages/project-details/project-details.scss';
import ProjectCommunityQuestionAnswers from '@/components/project-community-answer/project-accordian.js';
import Callback from '@/components/project-overview/request-callback.js';
import Slider from '@/components/project-overview/project-overview-slider.js';
import Reviewcard from '@/components/project-details/review-card.js';
import CommunityCard from '@/components/project-community-answer/community-answer-card.js';
import PropertyNavigation from '@/components/project-overview/project-navigation-menu.js';

export default class details extends React.Component {
  render() {
    return (
      <section className="property-details-wrapper">
        <PropertyNavigation />
        <div className="property-details-wrapper--image-slider">
          <Slider />
        </div>
        <div className="property-details-wrapper--overview">
          <div className="d-flex justify-content-between">
            <div className="property-overview">
              <CommunityCard />
             <div className="topics">
                 <h2 className="normal-font">Topics</h2>
                 <ul>
                     <li><a href="#0" className="active">All</a></li>
                     <li><a href="#0">Price</a></li>
                     <li><a href="#0">Maintenance</a></li>
                     <li><a href="#0">Car Parking</a></li>
                     <li><a href="#0">Club House</a></li>
                     <li><a href="#0">Pet Animals</a></li>
                     <li><a href="#0">Facilities</a></li>
                     <li><a href="#0">Others</a></li>
                 </ul>
             </div>
              <ProjectCommunityQuestionAnswers/>
            </div>
            <div className="details-callback-container d-none-mobile d-none-medium">
             <div className="details-callback">
              <Callback/>
            </div>  
            <div className="details-callback">
              <Reviewcard/>
            </div> 
            </div>
          </div>
        </div>
      </section>
    );
  }
}
