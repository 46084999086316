import React from 'react';
import Propertiescard from '@components/property-card/index';
import PropertySearchForm from '@components/property-search/property-search-form';
import PoppertyFilterPopup from '@components/property-search/property-filter-popup';
import '@/assets/scss/pages/search-results/search-results.scss';

export default class SearchProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResultsArray: [
        {
          reviewsCount: 43,
          price: '93 Lakhs',
          rating: 4,
          apartmentType: '3BHK Apartment',
          location: 'JP Nagar phase 9',
          superArea: '4500 Sqft',
          society: 'Prestige Spencer Heights',
          imageURL:
            '/images/properties/property-1.png'
        },
        {
          reviewsCount: 23,
          price: '50 Lakhs',
          rating: 3.5,
          apartmentType: '2BHK Apartment',
          location: 'JP Nagar phase 6',
          superArea: '2500 Sqft',
          society: 'Prestige Spencer Heights New',
          imageURL: '/images/properties/property-2.png'
        },
        {
          reviewsCount: 34,
          price: '30 Lakhs',
          rating: 2.8,
          apartmentType: '1BHK Apartment',
          location: 'JP Nagar phase 1',
          superArea: '1500 Sqft',
          society: 'Prestige Spencer',
          imageURL: '/images/properties/property-1.png'
        },
        {
          reviewsCount: 43,
          price: '93 Lakhs',
          rating: 4,
          apartmentType: '3BHK Apartment',
          location: 'JP Nagar phase 9',
          superArea: '4500 Sqft',
          society: 'Prestige Spencer Heights',
          imageURL: '/images/properties/property-2.png'
        },
        {
          reviewsCount: 23,
          price: '50 Lakhs',
          rating: 3.5,
          apartmentType: '2BHK Apartment',
          location: 'JP Nagar phase 6',
          superArea: '2500 Sqft',
          society: 'Prestige Spencer Heights New',
          imageURL: '/images/properties/property-2.png'
        },
        {
          reviewsCount: 34,
          price: '30 Lakhs',
          rating: 2.8,
          apartmentType: '1BHK Apartment',
          location: 'JP Nagar phase 1',
          superArea: '1500 Sqft',
          society: 'Prestige Spencer',
          imageURL: '/images/properties/property-1.png'
        },
        {
            reviewsCount: 43,
            price: '93 Lakhs',
            rating: 4,
            apartmentType: '3BHK Apartment',
            location: 'JP Nagar phase 9',
            superArea: '4500 Sqft',
            society: 'Prestige Spencer Heights',
            imageURL:
              '/images/properties/property-1.png'
          },
          {
            reviewsCount: 23,
            price: '50 Lakhs',
            rating: 3.5,
            apartmentType: '2BHK Apartment',
            location: 'JP Nagar phase 6',
            superArea: '2500 Sqft',
            society: 'Prestige Spencer Heights New',
            imageURL: '/images/properties/property-2.png'
          },
          {
            reviewsCount: 34,
            price: '30 Lakhs',
            rating: 2.8,
            apartmentType: '1BHK Apartment',
            location: 'JP Nagar phase 1',
            superArea: '1500 Sqft',
            society: 'Prestige Spencer',
            imageURL: '/images/properties/property-1.png'
          },
          {
            reviewsCount: 43,
            price: '93 Lakhs',
            rating: 4,
            apartmentType: '3BHK Apartment',
            location: 'JP Nagar phase 9',
            superArea: '4500 Sqft',
            society: 'Prestige Spencer Heights',
            imageURL: '/images/properties/property-2.png'
          },
          {
            reviewsCount: 23,
            price: '50 Lakhs',
            rating: 3.5,
            apartmentType: '2BHK Apartment',
            location: 'JP Nagar phase 6',
            superArea: '2500 Sqft',
            society: 'Prestige Spencer Heights New',
            imageURL: '/images/properties/property-2.png'
          },
          {
            reviewsCount: 34,
            price: '30 Lakhs',
            rating: 2.8,
            apartmentType: '1BHK Apartment',
            location: 'JP Nagar phase 1',
            superArea: '1500 Sqft',
            society: 'Prestige Spencer',
            imageURL: '/images/properties/property-1.png'
          }
      ],
      // flag to show/hide the property details popup
      showPropertyFilterPopup: false
    };
  }
  
  detectSafari = () => {
    var ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('safari') != -1) { 
        if (ua.indexOf('chrome') > -1) {
          document.querySelector('html').classList.add('chrome');
        } else {
          document.querySelector('html').classList.add('safari');
        }
    }
  }

  componentDidMount = () => {
    this.detectSafari();
  }

  tooglePropertyFilterPopup = () => {
    this.setState({
      showPropertyFilterPopup: !this.state.showPropertyFilterPopup
    })
  }

  render() {
    const {searchResultsArray} = this.state;
    return (
      <section className="search-properties-wrapper">
        <div className="custom-container">
          <PropertySearchForm onClick={this.tooglePropertyFilterPopup} />
          <div className="search-properties-wrapper--search-results">
            <h2 className="mb-3">Best properties (68)</h2>
            <div className="search-results">
                {searchResultsArray.map((propertyObj, index) => {
                    return (
                        <Propertiescard key={index} propertyPropObj={propertyObj} />
                    );
                })}
            </div>
          </div>
        </div>
        <PoppertyFilterPopup showPropertyFilter={this.state.showPropertyFilterPopup} onClick={this.tooglePropertyFilterPopup} />
      </section>
    );
  }
}
