import React from 'react';
import '@assets/scss/footer/site-footer.scss'; 
import Twittersvg from '@/assets/images/footer-icons/twitter.svg';
import Phonesvg from '@/assets/images/footer-icons/phone.svg';
import Mailsvg from '@/assets/images/footer-icons/mail.svg';
import Facebooksvg from '@/assets/images/footer-icons/facebook.svg';
import Instagramsvg from '@/assets/images/footer-icons/instagram.svg';
import Youtubesvg from '@/assets/images/footer-icons/youtube.svg';

export default class Header extends React.Component {
  render() {
    return (
      <footer className="footer-wrapper">
        <div className="custom-container">
          <div className="footer-wrapper--header d-flex align-items-center justify-content-between flex-direction-column-mobile">
            <div className="icon-logo d-flex align-items-center">
              <p className="mr-5">Follow us</p>
              <ul>
                <li>
                  <a href="#0">
                    <img src={Twittersvg} />
                  </a>
                </li>
                <li>
                  <a href="#0">
                    <img src={Facebooksvg} />
                  </a>
                </li>
                <li>
                  <a href="#0">
                    <img src={Instagramsvg} />
                  </a>
                </li>
                <li>
                  <a href="#0">
                    <img src={Youtubesvg} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="subscription-form-wrapper d-flex flex-direction-column-mobile mt-3 mt-md-0">
              <input type="text" placeholder="Enter your email to subscribe to newsletter" className="form-control" />
              <button className="submit ml-md-3 mt-2 mt-md-0">Submit</button>
            </div>
          </div>
          <div className="footer-wrapper--body">
            <div className="company-details d-flex justify-content-between flex-direction-column-mobile">
              <div className="logo">
                <div className="logo-wrapper d-flex align-items-center">
                  <h2 className="white-color">Logo</h2>
                </div>
                <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
                </p>
              </div>
              <div className="sales common">
                <h5 className="bold-font">SALES & SUPPORT</h5>
                <ul className="mt-4">
                  <li className="pb-2">
                    <div className="d-flex align-items-center">
                      <img src={Phonesvg} className="mr-3" />
                      +91 9845198451
                    </div>
                  </li>
                  <li className="pb-2">
                    <div className="d-flex align-items-center">
                      <img src={Mailsvg} className="mr-3" />
                      care@healthtunnel.in
                  </div>
                  </li>
                </ul>
              </div>
              <div className="others common">
                <h5 className="bold-font">OTHERS</h5>
                <ul className="mt-4">
                  <li><a href="#0">Home</a></li>
                  <li><a href="#0">Categories</a></li>
                </ul>
              </div>
              <div className="company common">
                <h5 className="bold-font">COMPANY</h5>
                <ul className="mt-4">
                  <li><a href="#0">About</a></li>
                  <li><a href="#0">FAQ'S</a></li>
                  <li><a href="#0">Blogs</a></li>
                  <li><a href="#0">Testimonials</a></li>
                  <li><a href="#0">Partner with us</a></li>
                  <li><a href="#0">Contact Us</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-wrapper--footer d-flex align-items-center justify-content-between mt-5 flex-direction-column-mobile">
            <div className="footer-links">
              <ul>
                <li>
                  <a href="/privacy-policy">Privacy</a>
                </li>
                <li>
                  <a href="#0">Sales Terms</a>
                </li>
                <li>
                  <a href="/terms-of-service">Terms of Service</a>
                </li>
              </ul>
            </div>
            <p>Copyright © Rebyuu. All Rights Reserved</p>
          </div>
        </div>
      </footer>
    );
  }
}
