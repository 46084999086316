import React from 'react';
import '@/assets/scss/pages/privacy-policy/banner.scss';

export default class InnerpageBanner extends React.Component {
    render () {
      return (
        <section className="inner-page-banner-section-wrapper section-padding">
            <div className="custom-container">
                <h1 className="white-color">Privacy</h1>
                <h3 className="white-color light-font">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s</h3>
            </div>
        </section>
      )
    }
}