import React from 'react';
import '@assets/scss/pages/reward-points/reward-points.scss';
import RewardSVG from '@assets/images/reward-points/rewards-image.svg';
import RewardCard from '@/components/manage-earned-points/reward-point-card.js';
import LeftArrow from '@/assets/images/generic-icons/left-arrow.svg';
import RightArrow from '@/assets/images/generic-icons/right-arrow.svg';
import RewardPopup from '@/components/manage-earned-points/reward-points-popup.js'

export default class Rewardpoints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rewardPointArray: [
        {
          imageURL: '/images/rewards/pepperfry.png',
          title: 'Redeem 100 Points',
          description:
            'Get 30% discount on any furniture purchase from pepper fry',
          link: 'Redeem No'
        },
        {
          imageURL: '/images/rewards/Flipkart-Logo.png',
          title: 'Redeem 1000 Points',
          description:
            'Get Rs.6000 discount on any Jewellery purchase from Tanishq',
          link: 'Redeem No'
        },
        {
          imageURL: '/images/rewards/Tanishq_Logo.png',
          title: 'Redeem 500 Points',
          description:
            'Get Rs.3000 discount on any Clothing purchase from Flipkart',
          link: 'Redeem No'
        },
        {
          imageURL: '/images/rewards/pepperfry.png',
          title: 'Redeem 100 Points',
          description:
            'Get 30% discount on any furniture purchase from pepper fry',
          link: 'Redeem No'
        },
        {
          imageURL: '/images/rewards/Flipkart-Logo.png',
          title: 'Redeem 1000 Points',
          description:
            'Get Rs.6000 discount on any Jewellery purchase from Tanishq',
          link: 'Redeem No'
        },
        {
          imageURL: '/images/rewards/Tanishq_Logo.png',
          title: 'Redeem 500 Points',
          description:
            'Get Rs.3000 discount on any Clothing purchase from Flipkart',
          link: 'Redeem No'
        },
        {
          imageURL: '/images/rewards/pepperfry.png',
          title: 'Redeem 100 Points',
          description:
            'Get 30% discount on any furniture purchase from pepper fry',
          link: 'Redeem No'
        },
        {
          imageURL: '/images/rewards/Flipkart-Logo.png',
          title: 'Redeem 1000 Points',
          description:
            'Get Rs.6000 discount on any Jewellery purchase from Tanishq',
          link: 'Redeem No'
        },
        {
          imageURL: '/images/rewards/Tanishq_Logo.png',
          title: 'Redeem 500 Points',
          description:
            'Get Rs.3000 discount on any Clothing purchase from Flipkart',
          link: 'Redeem No'
        }
      ],
      rewardsPopupFlag: false,
    };
  }
  toogleRewardPopup = () => {
    this.setState({
      rewardsPopupFlag: !this.state.rewardsPopupFlag
    })
  }
  render() {
    const { rewardPointArray } = this.state;
    return (
      <section className="rewards-wrapper">
        <RewardPopup rewardsPopupFlag={this.state.rewardsPopupFlag} closeClaimRewardsPopup={this.toogleRewardPopup} />
        <div className="rewards-wrapper--banner">
          <div className="banner-header text-center">
            <img src={RewardSVG} className="img-fluid" />
            <h1 className="medium-bold-font">Rebyuu Reward Points</h1>
            <p className="light-font">
              Earn points on every review you write. Redeem points for special
              discounts and offers from our partners listed below.
            </p>
          </div>
          <div className="banner-points text-center">
            <ul>
              <li>
                <div className="reward-circle d-flex border-green">
                  <h6 className="normal-font">200</h6>
                </div>
                <p>Total Points Earned</p>
              </li>
              <li>
                <div className="reward-circle d-flex border-red">
                  <h6 className="normal-font">100</h6>
                </div>
                <p>Total Points Redeemed</p>
              </li>
              <li>
                <div className="reward-circle d-flex border-orange">
                  <h6 className="normal-font">100</h6>
                </div>
                <p>Total Points Available</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="rewards-wrapper--body">
          <div className="custom-container">
            <div className="title">
              <h2 className="text-center">
                Redeem & get great benefits from our partners
              </h2>
            </div>
            <div className="reward-cards">
              {rewardPointArray.map((rewardObj, index) => {
                return <RewardCard key={index} rewardPropsObj={rewardObj} showClaimRewardsPopup={this.toogleRewardPopup} />;
              })}
            </div>
          </div>
        </div>
        <div className="rewards-wrapper--footer text-center mt-5">
          <ul className="d-flex justify-content-center">
            <li>
              <button className="mr-3 reward-button d-flex align-items-center">
                <img src={LeftArrow}/>
                <p>Previous</p>
              </button>
            </li>
            <li>
              <button className="reward-button d-flex align-items-center">
                Next
                <img src={RightArrow} className="ml-3" />
              </button>
            </li>
          </ul>
          <p>Showing results 2 - 24 of 68</p>
        </div>
      </section>
    );
  }
}
