import React from 'react';
import '@assets/scss/components/home/property-card.scss';
import StarRatings from 'react-star-ratings';

export default class Propertiescard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 4.4
    };
  }
  render() {
    return (
      <a className="properties-card-wrapper position-relative" href="/project-overview">
        <div className="properties-card-wrapper--image-wrapper">
          <img
            src={this.props.propertyPropObj.imageURL}
            className="img-fluid"
            alt="abc"
          />
        </div>
        <div className="img-tag position-absolute white-color">
          <h3 className="medium-bold-font white-color">
            {this.props.propertyPropObj.reviewsCount}
          </h3>
          <p className="light-font">Reviews</p>
        </div>
        <div className="card-details">
          <div className="d-flex align-items-center justify-content-between mb-1">
            <h3 className="medium-bold-font">
              {this.props.propertyPropObj.price}
            </h3>
            <StarRatings
              rating={this.props.propertyPropObj.rating}
              starRatedColor="#f9be2b"
              starDimension="24px"
              starSpacing="2px"
              numberOfStars={5}
              name="rating"
            />
          </div>
          <h4 className="normal-font mb-1">
            {this.props.propertyPropObj.apartmentType} in{' '}
            {this.props.propertyPropObj.location}
          </h4>
          <h6 className="normal-font mb-1">
            Super Area :{' '}
            {this.props.propertyPropObj?.superArea
              ? this.props.propertyPropObj.superArea
              : 'NA'}
          </h6>
          <h6 className="normal-font red">
            Society : {this.props.propertyPropObj.society}
          </h6>
        </div>
      </a>
    );
  }
}
