import Homepage from '@pages/home-page';
import Pagenotfound from '@pages/page-not-found';
import SearchProperties from '@pages/search-properties';
import Login from '@pages/login.js';
import Landingpage from '@pages/landing-page.js';
import Profile from '@pages/profile.js';
import Privacypolicy from '@pages/privacy-policy.js';
import Termsservice from '@pages/terms-of-service.js';
import Projectoverview from '@pages/project-overview.js';
import Projectdetails from '@pages/project-details.js';
import ProjectLocation from '@pages/project-location.js';
import ProjectReviews from '@/pages/project-reviews-page.js';
import CommunityAnswers from '@pages/project-community-answer.js';
import ManageEarnedPoints from '@pages/manage-earned-points.js';

const routes = [
	{ path: '/', name: 'home', component: Homepage },
	{ path: '/search-properties', name: 'SearchProperties', component: SearchProperties },
	{ path: '/login-register', name: 'Login', component: Login},
	{ path: '/landing-page', name: 'Landingpage', component: Landingpage},
	{ path: '/profile', name: 'Profile', component: Profile},
	{ path: '/privacy-policy', name: 'Privacypolicy', component: Privacypolicy},
	{ path: '/terms-of-service', name: 'Termsservice', component: Termsservice},
	{ path: '/project-overview', name: 'Projectoverview', component: Projectoverview},
	{ path: '/project-details', name: 'Projectdetails', component: Projectdetails},
	{ path: '/project-location', name: 'ProjectLocation', component: ProjectLocation},
	{ path: '/project-community-answer', name: 'CommunityAnswers', component: CommunityAnswers},
	{ path: '/project-reviews', name:'ProjectReviews', component: ProjectReviews},
	{ path: '/manage-rewards', name:'ManageEarnedPoints', component: ManageEarnedPoints},
	{ path: '*', name: 'dashboard', component: Pagenotfound }
];

export default routes;
