import React from 'react';
import '@assets/scss/components/home/rebyuee-features/features-card.scss';
export default class FeatureCard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="features-card-wrapper">
        <div className="features-card-wrapper--image">
          <img src={this.props.featurePropObj.imageURL} className="img-fluid w-100" />
        </div>
        <div className="features-card-wrapper--details">
          <h3 className="normal-font mt-2 mb-1">{this.props.featurePropObj.title}</h3>
          <a href="#0" className="normal-font">{this.props.featurePropObj.link}</a>
        </div>
      </div>
    );
  }
}
