import React from 'react';
import '@/assets/scss/components/project-review/review-card.scss';
import PersonIcon from '@/assets/images/generic-icons/person-icon.png';

export default class Reviews extends React.Component {
  render() {
    return (
      <section className="review-card-wrapper d-flex">
        <div className="review-card-wrapper--image">
            <img src={PersonIcon}/>
        </div>
        <div className="review-card-wrapper--details">
          <div className="review-card-title d-flex align-items-center justify-content-between">
            <div className="title">
              <h6 className="medium-bold-font">Rama Krishnamurthy</h6>
              <p className="small-font">3 Days ago on Rebyuu</p>
            </div>
            <div className="rating d-none-mobile">
              <h3>3.5/5</h3>
            </div>
          </div>
          <div className="review-text">
            <p className="light-font text-justify">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </div>
      </section>
    );
  }
}
