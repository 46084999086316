import React from 'react';
import '@/assets/scss/header/navigation-menu.scss';

export default class NavigationMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryMenuArray: [
        {
          iconURL: '/images/menu-icons/flats.svg',
          title: 'Flats',
          link: '/flats'
        },
        {
          iconURL: '/images/menu-icons/villas.svg',
          title: 'Villas',
          link: '/villas'
        },
        {
          iconURL: '/images/menu-icons/popular-properties.svg',
          title: 'Popular Properties',
          link: '/popular-properties'
        },
        {
          iconURL: '/images/menu-icons/new-on-rebyuu.svg',
          title: 'New on Rebyuu',
          link: '/new-on-rebyuu'
        }
      ],
      secondaryMenuArray: [
        {
          iconURL: '/images/menu-icons/Give-Feedback-Earn.svg',
          title: 'Give Feedback & Earn',
          link: '/flats'
        },
        {
          iconURL: '/images/menu-icons/partner-with-us.svg',
          title: 'Partner with us',
          link: '/partner-with-us'
        },
        {
          iconURL: '/images/menu-icons/get-answers.svg',
          title: 'Get Answers',
          link: '/popular-properties'
        }
      ],
      tertiaryMenuArray: [
        {
          iconURL: '/images/menu-icons/customer-stories.svg',
          title: 'Customer Stories',
          link: '/flats'
        },
        {
          iconURL: '/images/menu-icons/contact-us.svg',
          title: 'Contact us',
          link: '/partner-with-us'
        },
        {
          iconURL: '/images/menu-icons/FAQ.svg',
          title: 'FAQ’s',
          link: '/popular-properties'
        },
        {
          iconURL: '/images/menu-icons/blog.svg',
          title: 'Blog',
          link: '/popular-properties'
        }
      ]
    };
  }
  render() {
    const {
      primaryMenuArray,
      secondaryMenuArray,
      tertiaryMenuArray
    } = this.state;
    return (
      <div className={this.props.showMenu ? 'navigation-menu-wrapper active' : 'navigation-menu-wrapper'}>
        <div className="navigation-menu-wrapper--logo-wrapper">
          <h2>Logo</h2>
        </div>
        <div className="navigation-menu-wrapper--menu-primary">
          <ul>
            <li>
              <a href="/" className="active">
                <img
                  src="/images/menu-icons/home.svg"
                  className="img-fluid mr-3"
                />
                Home Page
              </a>
            </li>
          </ul>
        </div>
        <div className="navigation-menu-wrapper--menu-primary">
          <ul>
            {primaryMenuArray.map((menuObj, index) => {
              return (
                <li key={index}>
                  <a href={menuObj.link}>
                    <img src={menuObj.iconURL} className="img-fluid mr-3" />
                    {menuObj.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="navigation-menu-wrapper--menu-primary">
          <ul>
            {secondaryMenuArray.map((menuObj, index) => {
              return (
                <li key={index}>
                  <a href={menuObj.link}>
                    <img src={menuObj.iconURL} className="img-fluid mr-3" />
                    {menuObj.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="navigation-menu-wrapper--menu-primary">
          <ul>
            {tertiaryMenuArray.map((menuObj, index) => {
              return (
                <li key={index}>
                  <a href={menuObj.link}>
                    <img src={menuObj.iconURL} className="img-fluid mr-3" />
                    {menuObj.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="navigation-menu-wrapper--signin-button">
          <button className="theme-button theme-primary-btn w-100">
            Sign in
          </button>
        </div>
      </div>
    );
  }
}
