import React from 'react';
import { NavLink } from 'react-router-dom';

export default class location extends React.Component {
    render() {
        return (
            <div className="property-details-wrapper--nav text-center">
                <ul>
                    <li>
                        <NavLink to="/project-overview" exact>
                            Overview
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/project-details" exact>Project Details</NavLink>
                    </li>
                    <li>
                        <NavLink to="/project-location" exact>Location</NavLink>
                    </li>
                    <li>
                        <NavLink to="/project-reviews" exact>Reviews</NavLink>
                    </li>
                    <li>
                        <NavLink to="/project-community-answer" exact>Community Answers</NavLink>
                    </li>
                </ul>
            </div>
        )
    }
}