import React from 'react';
import '@/assets/scss/pages/project-overview/project-review.scss';
import Reviewicon from '@assets/images/property-overview/reviewicon.png';

export default class overview extends React.Component {
  render() {
    return (
      <div className="review-comments-container">
        <div className="icon d-flex">
          <img src={Reviewicon} className="mr-2" />
          <div className="title">
            <h4 className="normal-font">Ramesh Challapathi</h4>
            <h6 className="light-font">3 Days ago on Rebyuu</h6>
          </div>
        </div>
        <div className="comment">
          <p>
            “ I had a wonderful experience. The room was modern, pleasant and
            absolutely clean. The staff were courteous. Especially, Mr. Suresh
            at the reception has a way to make you feel home and happier even
            after a 30 sec long call for regular things.”
          </p>
        </div>
      </div>
    );
  }
}
