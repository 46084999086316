import React from 'react';
import '@assets/scss/pages/project-overview/project-overview.scss';
import Location from '@/components/project-overview/project-location.js';
import Callback from '@/components/project-overview/request-callback.js';
import Projectcard from '@/components/project-overview/project-overview-card.js';
import Propertyslider from '@/components/home-page/popular-property/popular-properties-slider.js';
import Summary from '@/components/project-overview/project-summary.js';
import Community from '@/components/project-overview/project-community-answer.js';
import Review from '@/components/project-overview/project-review.js';
import Slider from '@/components/project-overview/project-overview-slider.js';
import PropertyNavigation from '@/components/project-overview/project-navigation-menu.js';

export default class overview extends React.Component {
  render() {
    return (
      <section className="property-overview-wrapper">
        <PropertyNavigation />
        <div className="property-overview-wrapper--image-slider">
          <Slider />
        </div>
        <div className="property-overview-wrapper--overview">
          <div className="d-flex justify-content-between">
            <div className="property-overview">
              <Projectcard />
              <Summary />
              <Location />
            </div>
            <div className="review-comments d-none-mobile d-none-medium">
              <div className="community">
                <Review />
                <Review />
                <div className="link mt-2">
                  <a href="/project-reviews">View more Reviews</a>
                </div>
              </div>
              <div className="community mt-5">
                <Community />
              </div>
              <div className="community">
                <Callback />
                <div className="link mt-2">
                  <a href="/project-reviews">View more Reviews</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="property-overview-wrapper--similar-properties mt-5">
          <div className="custom-container">
            <h5 className="normal-font mb-4 text-center mt-4">
              Similar properties nearby
            </h5>
            <Propertyslider />
          </div>
        </div>
      </section>
    );
  }
}
