import React from 'react';
import '@/assets/scss/pages/project-details/configuration.scss';
import Appicon from '@/assets/images/generic-icons/getapp.svg';
export default class configuration extends React.Component {
  render() {
    return (
      <div className="project-configuration-wrapper">
        <h3 className="medium-bold-font">Unit Configuration</h3>
        <div className="card-detils-wrapper d-flex aling-items-center justify-content-between flex-direction-column-mobile">
          <div className="card-icon">
            <p>2BHK</p>
          </div>
          <div className="card-details">
            <h5 className="medium-bold-font">Apartment</h5>
            <p>INR 10.5 Cr - INR 15.5 Cr</p>
          </div>
          <div className="card-details">
            <h5 className="medium-bold-font text-right">
              Super Built-up area : 1200 sqft - 1600 sqft
            </h5>
            <p className="text-right">Carpet area : 1200 sqft - 1600 sqft</p>
          </div>
          <div className="card-details text-right">
            <a href="#0">
              <img src={Appicon} />
            </a>
            <p className="text-right">Floor Plan</p>
          </div>
        </div>
        <div className="card-detils-wrapper d-flex aling-items-center justify-content-between flex-direction-column-mobile">
          <div className="card-icon">
            <p>2BHK</p>
          </div>
          <div className="card-details">
            <h5 className="medium-bold-font">Apartment</h5>
            <p>INR 10.5 Cr - INR 15.5 Cr</p>
          </div>
          <div className="card-details">
            <h5 className="medium-bold-font text-right">
              Super Built-up area : 1200 sqft - 1600 sqft
            </h5>
            <p className="text-right">Carpet area : 1200 sqft - 1600 sqft</p>
          </div>
          <div className="card-details text-right">
            <a href="#0">
              <img src={Appicon} />
            </a>
            <p className="text-right">Floor Plan</p>
          </div>
        </div>
        <div className="card-detils-wrapper d-flex aling-items-center justify-content-between flex-direction-column-mobile">
          <div className="card-icon">
            <p>2BHK</p>
          </div>
          <div className="card-details">
            <h5 className="medium-bold-font">Apartment</h5>
            <p>INR 10.5 Cr - INR 15.5 Cr</p>
          </div>
          <div className="card-details">
            <h5 className="medium-bold-font text-right">
              Super Built-up area : 1200 sqft - 1600 sqft
            </h5>
            <p className="text-right">Carpet area : 1200 sqft - 1600 sqft</p>
          </div>
          <div className="card-details text-right">
            <a href="#0">
              <img src={Appicon} />
            </a>
            <p className="text-right">Floor Plan</p>
          </div>
        </div>
        <div className="card-detils-wrapper d-flex aling-items-center justify-content-between flex-direction-column-mobile">
          <div className="card-icon">
            <p>2BHK</p>
          </div>
          <div className="card-details">
            <h5 className="medium-bold-font">Apartment</h5>
            <p>INR 10.5 Cr - INR 15.5 Cr</p>
          </div>
          <div className="card-details">
            <h5 className="medium-bold-font text-right">
              Super Built-up area : 1200 sqft - 1600 sqft
            </h5>
            <p className="text-right">Carpet area : 1200 sqft - 1600 sqft</p>
          </div>
          <div className="card-details text-right">
            <a href="#0">
              <img src={Appicon} />
            </a>
            <p className="text-right">Floor Plan</p>
          </div>
        </div>
      </div>
    );
  }
}
