import React from 'react';
import '@assets/scss/components/home/popular-properties.scss';
import Popularpropertiesslider from '@components/home-page/popular-property/popular-properties-slider.js';
import Arrowback from '@assets/svg/arrowback.svg';
import Arrowforward from '@assets/svg/arrowforward.svg';
export default class Newrebuy extends React.Component {
  render() {
    return (
      <div className="popular-properties-wrapper">
        <div className="custom-container">
          <div className="popular-properties-wrapper--title d-flex align-items-md-baseline justify-content-between mb-4">
            <h2>New on Rebyuu</h2>
            <div className="popular-properties-wrapper--cta-wrapper d-flex align-items-center justify-content-between">
              <a href="#0" className="mr-5 pb-1 position-relative d-none-mobile">
                view all
              </a>
              <ul>
                <li>
                  <div className="swiper-button-prev arrow mr-2 mr-md-4">
                    {' '}
                    <img src={Arrowback} />{' '}
                  </div>
                </li>
                <li>
                  <div className="swiper-button-next arrow">
                    {' '}
                    <img src={Arrowforward} />{' '}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <Popularpropertiesslider />
        </div>
      </div>
    );
  }
}
