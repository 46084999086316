import React from 'react';
import '@/assets/scss/components/get-started/get-started.scss';

export default class getStarted extends React.Component {
  render() {
    return (
      <section className="get-started-wrapper text-center">
        <div className="custom-container">
            <div className="get-started-wrapper--title">
                <h1>LOGO</h1>
                <h2>Ready to get started?</h2>
            </div>
            <div className="get-started-wrapper--details">
                <h3 className="normal-font">See How Rebyuu can help you get more done with property search, property ratings and reviews. loved by thousands of users</h3>
                <div className="started-buttons">
                    <button className="theme-button theme-primary-btn">Search Now</button>
                </div>
            </div>
        </div>
      </section>
    );
  }
}
