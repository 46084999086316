import React from 'react';
import '@/assets/scss/pages/landingpage/landing-page.scss';
import Dropdownsvg from '@assets/svg/arrow-dropdown.svg';
import Addlocation from '@assets/svg/add-location.svg';

export default class Landingpage extends React.Component {
  render() {
    return (
      <section className="login-form-wrapper">
        <div className="custom-container d-flex justify-content-between flex-column-reverse flex-md-row">
          <div className="login-form-wrapper--form">
            <h4>LOGO</h4>
            <h2>
              Buying properties? Want property ratings and reviews? You’re In
              the right place
            </h2>
            <div className="form-element position-relative">
              <img src={Addlocation} className="position-absolute location" />
              <input type="text" placeholder="Select Property" className="form-changes"/>
              <img src={Dropdownsvg} />
            </div>
            <button className="theme-button theme-primary-btn w-100">
              Submit Now
            </button>
          </div>
          <div className="login-form-wrapper--banner-image">
            <img
              src="/images/login/login-banner.png"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>
    );
  }
}
