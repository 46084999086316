import React from 'react';
import '@/assets/scss/pages/search-results/property-filter-popup.scss';

export default class FilterProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersObj: {
        Price: ['Ready to move', 'Under construction'],
        'Sale Type': ['New', 'Resale'],
        'Posted Since': ['All', 'Yesterday', 'Last week', 'Last 2 weeks', 'Last 3 weeks', 'Last month', 'Last 2 months', 'Last 4 months'],
        Ownership: ['Freehold', 'Leasehold', 'Power of attorney', 'Co-operative society'],
        Bathroom: ['01', '02', '03', '04', '05', 'More than 5'],
        Furnishing: ['Furnished', 'Semi-furnished', 'Unfurnished'],
        Facing: ['East', 'North', 'North - East', 'North - West', 'South', 'South - East'],
        Floor: ['Basement', 'Ground', '1 - 4', '5 - 8'],
        Amenities: ['Vaastu compliant', 'Air conditioned', 'Internet/ Wi-fi connectivity', 'Piped gas', 'Power backup', 'Lift'],
        'Sub Property Type': ['Studio Apartment', 'Penthouse', 'Multistorey apartment', 'Builder floor apartment']
      }
    };
  }
  render() {
    const filtersHeading = Object.keys(this.state.filtersObj);
    return (
      <div
        className={
          this.props.showPropertyFilter
            ? 'filter-properties-popup-wrapper active'
            : 'filter-properties-popup-wrapper'
        }
      >
        <div className="form-wrapper">
          <div className="form-wrapper--header d-flex align-items-center justify-content-between flex-direction-column-mobile">
            <h5 className="normal-font">More Filters</h5>
            <div className="form-wrapper--header--cta">
              <ul>
                <li>
                  <a className="cursor-pointer" onClick={this.props.onClick}>
                    Clear all filters
                  </a>
                </li>
                <li>
                  <button
                    className="theme-button theme-primary-btn"
                    onClick={this.props.onClick}
                  >
                    Done
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="form-wrapper--body-wrapper">
            {filtersHeading.map((individualObj, index) => {
              return (
                <div key={index} className="filter-wrapper">
                  <h5 className="normal-font">{individualObj}</h5>
                  <ul>
                    {this.state.filtersObj[individualObj].map(
                      (filterItems, index) => {
                        return (
                          <li key={index}>
                            <input
                              type="radio"
                              id={filterItems}
                              name={individualObj}
                              value={filterItems}
                              className="filter-radio-button"
                            />
                            <label className="mb-0" htmlFor={filterItems}>
                              {filterItems}
                            </label>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
