import React from 'react';
// import Sitelogo from '@/assets/svg/logo/logo.svg';
import Mainsection from '@/components/login-register/login-form-banner.js';
import '@/assets/scss/pages/login/login.scss';

export default class Privacypolicy extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        setTimeout(() => {
            const header = document.querySelector('.header-wrapper');
            const subFooter = document.querySelector('.get-started-wrapper');
            const footer = document.querySelector('.footer-wrapper');
            if (header) {header.remove();}
            if (subFooter) {subFooter.remove();}
            if (footer) {footer.remove();}
        }, 100)
    }
    render() {
        return (
            <section className="login-page-wrapper">
               <div className="custom-container">
                    <a href="/" className="login-page-wrapper--logo d-flex align-items-center mb-4">
                        {/* <img src={Sitelogo} className="img-fluid" width="38" height="38" alt="HEALTH TUNNEL"/> */}
                        {/* <h6 className="ml-2 medium-font">HEALTH TUNNEL</h6> */}
                        <h4>LOGO</h4>
                    </a>
                    <Mainsection />
               </div>
            </section>
        )
    }
}