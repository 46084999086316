import React from 'react';
import '@assets/scss/pages/project-overview/project-overview-location.scss';
import MapSVG from '@/assets/images/property-overview/map.png';
import Mask from '@/assets/images/property-overview/mask.svg';
import LocateSVG from '@/assets/images/property-overview/location.svg';
import MarketSVG from '@/assets/images/property-overview/market.svg';
import HospitalSVG from '@/assets/images/property-overview/hospital.svg';
import RestorentSVG from '@/assets/images/property-overview/restorent.svg';

export default class location extends React.Component {
  render() {
    return (
      <div className="project-location">
        <h2 className="normal-font">Location Summary</h2>
        <h4 className="normal-font mt-2 red-color">Whitefield</h4>
        <p className="light-font">This locality is recommended for Retirees, Family and Couple</p>
        <img src={MapSVG} className="img-fluid w-100" />
        <h4 className="normal-font mt-2 red-color">Nearby places</h4>
        <div className="near-places-wrapper">
          <div className="location-card d-flex justify-content-between align-items-center">
            <div className="icon">
              <img src={LocateSVG} />
              <p className="light-font">school</p>
            </div>
            <img src={Mask} />
          </div>
          <div className="location-card d-flex justify-content-between align-items-center">
            <div className="icon">
              <img src={LocateSVG} />
              <p className="light-font">Restaurants</p>
            </div>
            <img src={RestorentSVG} />
          </div>
          <div className="location-card d-flex justify-content-between align-items-center">
            <div className="icon">
              <img src={LocateSVG} />
              <p className="light-font">Hospitals</p>
            </div>
            <img src={HospitalSVG} />
          </div>
          <div className="location-card d-flex justify-content-between align-items-center">
            <div className="icon">
              <img src={LocateSVG} />
              <p className="light-font">Market</p>
            </div>
            <img src={MarketSVG} />
          </div>
        </div>
        <div className="link mt-3 text-center">
          <a href="/project-details" className="theme-link-color">View more project details</a>
        </div>
      </div>
    );
  }
}
