import React from 'react';
import '@/assets/scss/pages/profile/index.scss';

export default class Profile extends React.Component {
    render() {
        return (
            <section className="user-profile-page-wrapper section-padding">
                <div className="custom-container">
                    <div className="user-profile-page-wrapper--profile-image d-flex" style={{backgroundImage: 'url(/images/profile/profile-background.svg)'}}>
                        <div className="profile-photo m-auto"></div>
                    </div>
                    <div className="user-profile-page-wrapper--welcome-wrapper text-center mt-4 mb-4">
                        <h3>Welcome, John Smith</h3>
                        <p>Get the best out of Healthtunnel by adding the details!</p>
                    </div>
                    <div className="user-profile-page-wrapper--profile-details">
                        <h4>Profile</h4>
                        <p className="normal-font mb-4">Never Miss a Great Healthcare Solutions offered on a Single Platform</p>
                        <ul>
                            <li><span className="label">Name</span> <div className="basic-details">Mr. John Smith</div></li>
                            <li><span className="label">Email Address</span> 
                                <div className="basic-details">
                                    Jamessmith@aol.com 
                                    <div className="basic-details--cta-wrapper">
                                        <a className="display-block cursor-pointer theme-primary-color small-font">Verified</a>
                                    </div>
                                </div>
                            </li>
                            <li><span className="label">Mobile Number</span> 
                                <div className="basic-details">+1 9845198451 
                                    <div className="basic-details d-flex">
                                        <div className="basic-details--cta-wrapper">
                                            <a className="display-block cursor-pointer theme-primary-color small-font"><span className="theme-secondary-color display-inline-block mr-2">Not Verified,</span>Verify Now</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}