import React from 'react';
import '@assets/scss/components/home/popular-properties-slider.scss';
import CustomerCard from '@/components/home-page/customer-stories/customer-story-card.js';
import Swiper from 'swiper/bundle';
import 'swiper/swiper.scss';

export default class Customerstories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CustomerstoriesArray: [
        {
          stories: 'Stories',
          heading: 'Lorem Ipsum is simply dummy text of the printing',
          date: 'November 20, 2020',
          imgURL:'https://i.ibb.co/8xhv5kz/odn-Gz-NVE49.png'
        },
        {
          stories: 'Stories',
          heading: 'Lorem Ipsum is simply dummy text of the printing',
          date: 'November 3, 2022',
          imgURL:'https://i.ibb.co/WDHz9QT/Z7-GTe-ZJz9-R.png'
        },
        {
          stories: 'Stories',
          heading: 'Lorem Ipsum is simply dummy text of the printing',
          date: 'November 30, 2021',
          imgURL:'https://i.ibb.co/hKNVrtf/Indian-Family-celebrating.png'
        },
        {
            stories: 'Stories',
            heading: 'Lorem Ipsum is simply dummy text of the printing',
            date: 'November 20, 2020',
            imgURL:'https://i.ibb.co/8xhv5kz/odn-Gz-NVE49.png'
          },
          {
            stories: 'Stories',
            heading: 'Lorem Ipsum is simply dummy text of the printing',
            date: 'November 3, 2022',
            imgURL:'https://i.ibb.co/WDHz9QT/Z7-GTe-ZJz9-R.png'
          },
          {
            stories: 'Stories',
            heading: 'Lorem Ipsum is simply dummy text of the printing',
            date: 'November 30, 2021',
            imgURL:'https://i.ibb.co/hKNVrtf/Indian-Family-celebrating.png'
          }
      ]
    };
  }
  initializeCustomerCardSlider() {
    new Swiper('.CustomerCard-swiper-container', {
      loop: false,
      slidesPerView: 'auto',
      autoplay: {
        delay: 5000
      },
      spaceBetween: 16,
      navigation: {
        nextEl: '.CustomerCard-slide-right',
        prevEl: '.CustomerCard-slide-left'
      }
    });
  }
  componentDidMount() {
    this.initializeCustomerCardSlider();
  }
  render() {
      const CustomerstoriesArray = this.state.CustomerstoriesArray;
    return (
      <div className="customer-card-wrapper--slider">
        <div className="swiper-container CustomerCard-swiper-container">
          <div className="swiper-wrapper">
              {CustomerstoriesArray.map((storyObj, index) => {
                  return (
                      <div key={index} className="swiper-slide property-card-width">
                           <CustomerCard CustomerstoriesPropObj={storyObj}/>
                      </div>
                  )
              }
              )}
            {/* <div class="swiper-slide property-card-width">
              <CustomerCard />
            </div>
            <div class="swiper-slide property-card-width">
              <CustomerCard />
            </div>
            <div class="swiper-slide property-card-width">
              <CustomerCard />
            </div>
            <div class="swiper-slide property-card-width">
              <CustomerCard />
            </div>
            <div class="swiper-slide property-card-width">
              <CustomerCard />
            </div>
            <div class="swiper-slide property-card-width">
              <CustomerCard />
            </div>
            <div class="swiper-slide property-card-width">
              <CustomerCard />
            </div>
            <div class="swiper-slide property-card-width">
              <CustomerCard />
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
