import React from 'react';
import Dropdownsvg from '@assets/svg/arrow-dropdown.svg';
import { Multiselect } from 'multiselect-react-dropdown';
import { FormGroup, Input, Label } from 'reactstrap';
import FilterSVG from '@/assets/svg/filterSVG.js'
import DropdownSVG from '@/assets/svg/dropdown.js';
import '@assets/scss/components/home/property-search.scss';

export default class SearchPropertiesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationOptions: [
        { name: 'Bangalore-North', id: 1 },
        { name: 'Bangalore-South', id: 2 }
      ],
      PropertyTypeOptions: [
        { name: '1 BHK', id: 1 },
        { name: '2 BHK', id: 2 }
      ],
      budgetType: 'Min',
      customizeMultiselect: {
        searchBox: {
          minHeight: '50px',
          lineHeight: '50px',
          padding: '0px 16px',
          borderColor: '#DADCE0' 
        },
        chips: {
          background: '#E8F0FE',
          color: '#1A73E8',
          height: '40px',
          padding: '10px 24px',
          borderRadius: '20px'
        },
        option: {
          background: '#ffffff',
          color: '#5F6368'
        }
      }
    };
  }
  onSelectLocation = (selectedList, selectedItem) => {
    console.log(selectedList, selectedItem);
  };
  onRemoveLocation = (selectedList, removedItem) => {
    console.log(selectedList, removedItem);
  };
  getBudgetType = (e) => {
    this.setState({
      budgetType: e.target.getAttribute('data-budget-type')
    })
  };
  render() {
    return (
      <div className="search-properties-form-wrapper property-search-form-wrapper d-flex align-items-center justify-content-between">
        <Multiselect
          options={this.state.locationOptions} // Options to display in the dropdown
          selectedValues={this.state.selectedLocation} // Preselected value to persist in dropdown
          onSelect={this.onSelectLocation} // Function will trigger on select event
          onRemove={this.onRemoveLocation} // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
          placeholder="Enter the location"
          emptyRecordMsg=""
          style={this.state.customizeMultiselect}
        />
        <Multiselect
          options={this.state.PropertyTypeOptions} // Options to display in the dropdown
          selectedValues={this.state.selectedLocation} // Preselected value to persist in dropdown
          onSelect={this.onSelectLocation} // Function will trigger on select event
          onRemove={this.onRemoveLocation} // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
          placeholder="Property Type"
          emptyRecordMsg=""
          style={this.state.customizeMultiselect}
        />
        <FormGroup className="property-dropdown position-relative mb-0 ml-md-2">
          <div className="form-control centered-text">
            Budget
            <img
              src={Dropdownsvg}
              className="position-absolute  new-img-position"
            />
          </div>
          <div className="property-dropdown--options position-absolute">
            <h6 className="light-font">Select Your Budget</h6>
            <div className="property-dropdown--options--min-max-range d-flex">
              <input type="text" placeholder="Min" className="form-control flex-1 mr-2" data-budget-type="Min" onFocus={this.getBudgetType} />
              <input type="text" placeholder="Max" className="form-control flex-1" data-budget-type="Max" onFocus={this.getBudgetType} />
            </div>
            <ul>
              <li>
                { this.state.budgetType }
              </li>
              <li>
                <Label check>
                  <Input type="radio" className="position-relative" name="minimum-budget"/>
                  <span className="display-inline-block ml-3">
                    Less than INR 50 Lakh
                  </span>
                </Label>
              </li>
              <li>
                <Label check>
                  <Input type="radio" className="position-relative" name="minimum-budget"/>
                  <span className="display-inline-block ml-3">
                    INR 50 Lakh to INR 75 lakh
                  </span>
                </Label>
              </li>
              <li>
                <Label check>
                  <Input type="radio" className="position-relative" name="minimum-budget"/>
                  <span className="display-inline-block ml-3">
                    INR 75 lakh to INR 1 Crore
                  </span>
                </Label>
              </li>
              <li>
                <Label check>
                  <Input type="radio" className="position-relative" name="minimum-budget"/>
                  <span className="display-inline-block ml-3">
                    INR 1 Crore to INR 1.25 Crore
                  </span>
                </Label>
              </li>
            </ul>
          </div>
        </FormGroup>
        <div className="more-filters-wrapper ml-md-2">
            <button className="more-filters-wrapper--button" onClick={this.props.onClick}>
                <FilterSVG />
                <span className="ml-2 mr-1">More Filters</span>
                <DropdownSVG />
            </button>
        </div>
      </div>
    );
  }
}
