import React from 'react';
import Banner from '@components/home-page/banner/index.js';
import Popularproperties from '@components/home-page/popular-property/index.js';
import Newrebuyy from '@components/home-page/new-rebuy/index.js';
import Rebyueefeatures from '@/components/home-page/rebyuee-features/index.js';
import PropertType from '@/components/home-page/property-type/property-type.js';
import CustomerStory from '@/components/home-page/customer-stories/index.js';

export default class Homepage extends React.Component {
	detectSafari = () => {
		var ua = navigator.userAgent.toLowerCase(); 
		if (ua.indexOf('safari') != -1) { 
			if (ua.indexOf('chrome') > -1) {
				document.querySelector('html').classList.add('chrome');
			} else {
				document.querySelector('html').classList.add('safari');
			}
		}
	}
	componentDidMount = () => {
		this.detectSafari();
	}
	render() {
		return (
			<div className="home-page-wrapper">
				<Banner />
				<Popularproperties />
				<Newrebuyy />
				<Rebyueefeatures/>
				<PropertType/>
				<CustomerStory/>
			</div>
		);
	}
}
