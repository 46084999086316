import React from 'react';
import DirectionSVG from '@assets/images/property-overview/directions.svg';
import ShareSVG from '@/assets/images/property-overview/share.svg';
import '@assets/scss/pages/project-overview/project-overview-card.scss';
import FaviconSVG from '@/assets/images/property-overview/fav-icon.svg';
import StarRatings from 'react-star-ratings';


export default class overview extends React.Component {
  render() {
    return (
      <div className="property-card-wrapper">
        <div className="card-title-name d-flex aling-items-center justify-content-between flex-direction-column-mobile">
          <h2 className="normal-font">43 Lakhs 5 BHK Penthouse</h2>
          <img src={FaviconSVG} className="d-none-mobile"/>
        </div>
        <h4 className="normal-font">
          For sale in UKN Miraya Rose, Whitefield, Bangalore
        </h4>
        <div className="d-flex align-items-center mt-2 mb-2">
          <StarRatings
            rating={3.5}
            starRatedColor="#f9be2b"
            starDimension="22px"
            starSpacing="2px"
            numberOfStars={5}
            name="rating"
          />
          <h5 className="normal-font ml-4"><a href="/project-reviews" className="theme-link-color">69 Property Reviews</a></h5>
        </div>
        <p className="light-font">Posted on Jan 12, 2021</p>
        <div className="card-buttons d-flex justify-content-between flex-direction-column-mobile align-items-center mt-3 mt-sm-3 mt-lg-3">
          <div className="d-flex justify-content-between-mobile">
            <button className="d-flex align-items-center theme-button direction-button">
              <img src={DirectionSVG} className="mr-2" />
              Direction
            </button>
            <button className="d-flex align-items-center theme-button direction-button">
              <img src={ShareSVG} className="mr-2" />
              Share
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between-mobile">
            <a href="#0">+ Write a Review</a>
            <button className="ml-3 theme-button theme-primary-btn">Request call back</button>
          </div>
        </div>
      </div>
    );
  }
}
