import React from 'react';
import '@assets/scss/pages/page-not-found.scss';
import { NavLink } from 'react-router-dom';

export default class Bookings extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className="page-not-found-wrapper text-center section-padding">
				<img src="/images/generic-icons/not-found.svg" width="200" className="img-fluid" alt="" />
				<h1>#Error 404</h1>
				<p className="mb-3">
					The page you are looking for doesn’t exits . We wll take you back
					home.
				</p>
				<NavLink to="/" className="btn btn-primary">
					Home Page
				</NavLink>
			</div>
		);
	}
}
