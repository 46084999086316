import React from 'react';
import Banner from '@/components/terms-of-service/banner.js';
import Innerpage from '@/components/terms-of-service/inner-page.js';

export default class Privacypolicy extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="privacy-policy-wrapper">
                <Banner />
                <Innerpage />
            </div>
        )
    }
}