import React from 'react';
import '@assets/scss/components/home/property-type.scss';
import Arrowback from '@assets/svg/arrowback.svg';
import Arrowforward from '@assets/svg/arrowforward.svg';
import PropertyTypeSlider from '@/components/home-page/property-type/property-type-slider.js';

export default class PropertyType extends React.Component {
  render() {
    return (
      <div className="property-type-wrapper">
        <div className="custom-container">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="property-type-wrapper--title">
              <h2 className="medium-bold-font">
                Looking for something else
              </h2>
            </div>
            <div className="property-type-wrapper--cta-wrapper">
              <ul>
                <li>
                  <div className="swiper-button-prev arrow mr-2 mr-md-4 property-type-slide-left">
                    <img src={Arrowback} />
                  </div>
                </li>
                <li>
                  <div className="swiper-button-next arrow property-type-slide-right">
                    <img src={Arrowforward} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <PropertyTypeSlider/>
        </div>
      </div>
    );
  }
}
