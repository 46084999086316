import React from 'react';
// Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label
import { FormGroup, Input, Label } from 'reactstrap';
import '@assets/scss/components/home/property-search.scss';
import Addlocation from '@assets/svg/add-location.svg';
import Dropdownsvg from '@assets/svg/arrow-dropdown.svg';

export default class Propertysearchform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: true,
      budgetType: 'Min'
    };
  }
  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };
  getBudgetType = (e) => {
    this.setState({
      budgetType: e.target.getAttribute('data-budget-type')
    })
  }
  rediretToSearch = () => {
    window.location.href = "/search-properties"
  }
  render() {
    return (
      <div className="property-search-form-wrapper d-flex align-items-center justify-content-between">
        <FormGroup className="mb-0 position-relative">
          <img src={Addlocation} className="position-absolute img-position" />
          <Input
            type="email"
            name="email"
            id="exampleEmail"
            placeholder="Enter the location"
          />
        </FormGroup>
        <FormGroup className="property-dropdown mb-0 position-relative  ml-2">
          <div className="form-control centered-text position-relative">
            Property Type
            <img
              src={Dropdownsvg}
              className="position-absolute new-img-position"
            />
          </div>
          <div className="property-dropdown--options position-absolute">
            <h6 className="light-font">Select property type</h6>
            <ul>
              <li>
                <Label check>
                  <Input type="checkbox" className="position-relative" />{' '}
                  <span className="display-inline-block ml-3">
                    House / Villa
                  </span>
                </Label>
              </li>
              <li>
                <Label check>
                  <Input type="checkbox" className="position-relative" />{' '}
                  <span className="display-inline-block ml-3">Flat</span>
                </Label>
              </li>
            </ul>
          </div>
        </FormGroup>
        <FormGroup className="property-dropdown mb-0 position-relative  ml-2">
          <div className="form-control centered-text">
            Budget
            <img
              src={Dropdownsvg}
              className="position-absolute  new-img-position"
            />
          </div>
          <div className="property-dropdown--options position-absolute">
            <h6 className="light-font">Select Your Budget</h6>
            <div className="property-dropdown--options--min-max-range d-flex">
              <input type="text" placeholder="Min" className="form-control flex-1 mr-2" data-budget-type="Min" onFocus={this.getBudgetType} />
              <input type="text" placeholder="Max" className="form-control flex-1" data-budget-type="Max" onFocus={this.getBudgetType} />
            </div>
            <ul>
              <li>
                { this.state.budgetType }
              </li>
              <li>
                <Label check>
                  <Input type="radio" className="position-relative" name="minimum-budget"/>
                  <span className="display-inline-block ml-3">
                    Less than INR 50 Lakh
                  </span>
                </Label>
              </li>
              <li>
                <Label check>
                  <Input type="radio" className="position-relative" name="minimum-budget"/>
                  <span className="display-inline-block ml-3">
                    INR 50 Lakh to INR 75 lakh
                  </span>
                </Label>
              </li>
              <li>
                <Label check>
                  <Input type="radio" className="position-relative" name="minimum-budget"/>
                  <span className="display-inline-block ml-3">
                    INR 75 lakh to INR 1 Crore
                  </span>
                </Label>
              </li>
              <li>
                <Label check>
                  <Input type="radio" className="position-relative" name="minimum-budget"/>
                  <span className="display-inline-block ml-3">
                    INR 1 Crore to INR 1.25 Crore
                  </span>
                </Label>
              </li>
            </ul>
          </div>
        </FormGroup>
        <FormGroup className="mb-0 search-button ml-2">
          <button className="theme-button theme-primary-btn w-100" onClick={this.rediretToSearch}>Search now</button>
        </FormGroup>
      </div>
    );
  }
}
