import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import '@/assets/scss/pages/project-community/ask-question-popup.scss';
import CloseSVG from '@/assets/svg/closeSVG.js';
import StarRatings from 'react-star-ratings';

export default class reviewPropertyPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rating: 0
        }
    }
    changeRating = (newRating) => {
        this.setState({
            rating: newRating
        });
    }
    clearRatings = () => {
        this.setState({
            rating: 0
        });
    }
    render() {
        return (
            <div className={this.props.reviewPropertyPopupFlag ? 'ask-question-popup-wrapper active' : 'ask-question-popup-wrapper'}>
                <div className="ask-question-popup-wrapper--popup position-relative">
                    <a className="close-popup position-absolute cursor-pointer" onClick={this.props.closePopup}>
                        <CloseSVG />
                    </a>
                    <h2>Add your Review and Ratings</h2>
                    <p>For UKN Miraya Rose in Whitefield</p>
                    <FormGroup className="mt-3 mb-3 ask-question-form-element">
                        <div className="d-flex align-items-center justify-content-between">
                            <Label for="rate-property" className="mb-0 ml-0 normal-font">Rate the property</Label>
                            <a className="cursor-pointer theme-link-color" onClick={this.clearRatings}>Clear</a>
                        </div>
                        <div className="ratings-wrapper mt-3 mb-4 text-center">
                            <StarRatings
                                rating={this.state.rating}
                                starRatedColor="#f9be2b"
                                starHoverColor="#f9be2b"
                                starDimension="24px"
                                starSpacing="16px"
                                numberOfStars={5}
                                changeRating={this.changeRating}
                                name="rating"
                            />
                        </div>
                        <Input type="textarea" name="text" id="rate-property" rows="4" />
                    </FormGroup>
                    <div className="ask-question-popup-wrapper--cta-wrapper text-right">
                        <button className="theme-button theme-secondary-btn" onClick={this.props.closePopup}>Cancel</button>
                        <button className="theme-button theme-primary-btn">Save</button>
                    </div>
                </div>
            </div>
        )
    }
}