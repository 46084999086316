import React from 'react';
import '@/assets/scss/pages/project-overview/project-review.scss';
import Reviewicon from '@/assets/images/generic-icons/help-center.svg';

export default class overview extends React.Component {
  render() {
    return (
      <div className="community-answer-container">
        <h4 className="normal-font mb-3">Community Answers</h4>
        <div className="icon d-flex mb-4">
          <img src={Reviewicon} className="mr-3" />
          <div className="title">
              <p>What is the monthly maintenance for 2 BHK?</p>
          </div>
        </div>
        <div className="icon d-flex mb-4">
          <img src={Reviewicon} className="mr-3" />
          <div className="title">
              <p>Is there any restrictions to conduct dance classes at home?</p>
          </div>
        </div>
        <div className="icon d-flex mb-4">
          <img src={Reviewicon} className="mr-3" />
          <div className="title">
              <p>What is the maximum number of car parking space one can buy?</p>
          </div>
        </div>
      </div>
    );
  }
}
