import React from 'react';
// import DirectionSVG from '@assets/images/property-overview/directions.svg';
// import ShareSVG from '@/assets/images/property-overview/share.svg';
import '@/assets/scss/pages/project-community/community-answer.scss';
import Community from '@/assets/images/property-overview/askcommunity.png';
// import StarRatings from 'react-star-ratings';
import AskAQuestionPopup from '@/components/project-community-answer/ask-a-question-popup.js';


export default class overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      askAQuestionPopupFlag: false,
    }
  }
  toogleAskQuestionPopup = () => {
    this.setState({
      askAQuestionPopupFlag: !this.state.askAQuestionPopupFlag
    })
  }
  render() {
    return (
      <div className="community-answer-wrapper">
        <div className="card-title d-flex align-items-center justify-content-between">
          <h4 className="medium-bold-font">Community Answers</h4>
          <a className="cursor-pointer" onClick={this.toogleAskQuestionPopup}>+ Ask a question</a>
        </div>
        <h5 className="normal-font">UKN Miraya Rose in Whitefield</h5>
        <p className="light-font">Developed / Built by UKN Properties pvt ltd</p>
        <div className="community-answer-wrapper--community-image text-right">
          <img src={Community} className="img-fluid" />
        </div>
        <div className="community-answer-wrapper--counts d-flex">
          <div className="count-card">
            <h1>33</h1>
            <p>Questions</p>
          </div>
          <div className="count-card mr-5 ml-5">
            <h1>103</h1>
            <p>Conversations</p>
          </div>
          <div className="count-card">
            <h1>07</h1>
            <p>Topics</p>
          </div>
        </div>
        <AskAQuestionPopup askAQuestionPopupFlag={this.state.askAQuestionPopupFlag} closePopup={this.toogleAskQuestionPopup} />
      </div>
    );
  }
}
