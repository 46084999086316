import React from 'react';
import '@/assets/scss/pages/project-overview/project-request.scss';
import { Form, FormGroup, Input } from 'reactstrap';

export default class callbackrequest extends React.Component {
  render() {
    return (
      <div className="callbackrequest-container">
        <h4 className="normal-font mb-3">Request a call back</h4>
        <Form>
            <FormGroup>
                <Input type="text" placeholder="Enter Name" />
            </FormGroup>
            <FormGroup>
                <Input type="text" placeholder="Enter Email ID" />
            </FormGroup>
            <FormGroup>
                <Input type="text" placeholder="Enter Phone Number" />
            </FormGroup>
            <button className="theme-button theme-primary-btn w-100">
                Request a call back
            </button>
        </Form>
      </div>
    );
  }
}
