import React from 'react';
import '@assets/scss/components/home/property-type-slider.scss';
import PropertyTypeCard from '@/components/home-page/property-type/property-type-card.js';
import Swiper from 'swiper/bundle';
import 'swiper/swiper.scss';

export default class PropertyTypeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyTypeArray:[
        {
          imgURL:'https://i.ibb.co/yRX5Hv8/pexels-expect-best-323780.png',
          heading:"2BHK Flat"
        },
        {
          imgURL:'https://i.ibb.co/7WjTqz3/pexels-jonathan-borba-5563472.png',
          heading:"5BHK Villa"
        },
        {
          imgURL:'https://i.ibb.co/dm0GgQG/pexels-jovydas-pinkevicius-2462015.png',
          heading:"1BHK House"
        },
        {
          imgURL:'https://i.ibb.co/yRX5Hv8/pexels-expect-best-323780.png',
          heading:"2BHK Flat"
        },
        {
          imgURL:'https://i.ibb.co/7WjTqz3/pexels-jonathan-borba-5563472.png',
          heading:"5BHK Villa"
        },
        {
          imgURL:'https://i.ibb.co/dm0GgQG/pexels-jovydas-pinkevicius-2462015.png',
          heading:"1BHK House"
        },
        {
          imgURL:'https://i.ibb.co/yRX5Hv8/pexels-expect-best-323780.png',
          heading:"2BHK Flat"
        },
        {
          imgURL:'https://i.ibb.co/7WjTqz3/pexels-jonathan-borba-5563472.png',
          heading:"5BHK Villa"
        },
        {
          imgURL:'https://i.ibb.co/dm0GgQG/pexels-jovydas-pinkevicius-2462015.png',
          heading:"1BHK House"
        }
      
      ]
    }
  }
  initializePropertySlider() {
    new Swiper('.property-type-swiper-container', {
      loop: false,
      slidesPerView: 6,
      autoplay: {
        delay: 5000
      },
      spaceBetween: 24,
      navigation: {
        nextEl: '.property-type-slide-right',
        prevEl: '.property-type-slide-left'
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 0, 
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        992: {
            slidesPerView: 4,
            spaceBetween: 24,
        },
        1200: {
            slidesPerView: 6,
            spaceBetween: 24,
        },
     },
    });
  }
  componentDidMount() {
    this.initializePropertySlider();
  }
  render() {
    const propertyTypeArray = this.state.propertyTypeArray;
    return (
      <div className="popular-properties-wrapper--slider">
        <div className="swiper-container property-type-swiper-container">
          <div className="swiper-wrapper">
          {propertyTypeArray.map((typeObj, index) => {
                  return (
                      <div key={index} className="swiper-slide property-card-width">
                           <PropertyTypeCard propertyTypeArrayPropObj={typeObj}/>
                      </div>
                  )
              }
              )}
            {/* <div class="swiper-slide">
              <PropertyTypeCard />
            </div>
            <div class="swiper-slide">
              <PropertyTypeCard />
            </div>
            <div class="swiper-slide">
              <PropertyTypeCard />
            </div>
            <div class="swiper-slide">
              <PropertyTypeCard />
            </div>
            <div class="swiper-slide">
              <PropertyTypeCard />
            </div>
            <div class="swiper-slide">
              <PropertyTypeCard />
            </div>
            <div class="swiper-slide">
              <PropertyTypeCard />
            </div>
            <div class="swiper-slide">
              <PropertyTypeCard />
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
